import permissions from "store/permissions/permissions";

export const MENUS_ROUTE = '/admin/menus';
export const AUTH_ROUTE = '/auth';
export const AUTH_SIGN_IN_ROUTE = '/auth/sign-in';
export const AUTH_SIGN_UP_ROUTE = '/auth/sign-up';
export const DASHBOARD_ROUTE = '/admin/dashboard';
export const RESTAURANTS_ROUTE = '/admin/restaurants';
export const ORDERS_ROUTE = '/admin/orders';
export const FLOORS_ROUTE = '/admin/floors'
export const MENU_MODIFIER_ITEMS_ROUTE = '/admin/menu-modifier-items';
export const SETTINGS_ROUTE = '/admin/settings';
export const LOCATIONS_ROUTE = '/admin/locations';
export const EXPENSES_ROUTE = '/admin/expenses';
export const PERMISSIONS_ROUTE = '/admin/permissions';
export const PERMISSION_SETS_ROUTE = '/admin/permission-sets';
export const EMPLOYEES_ROUTE = '/admin/employees';
export const MENU_GROUPS_ROUTE = '/admin/menu-groups';
export const MENU_MODIFIER_GROUPS_ROUTE = '/admin/menu-modifier-groups';
export const MENU_ITEMS_ROUTE = '/admin/menu-items';
export const INVENTORY_ROUTE = '/admin/inventory';
export const PURCHASES_ROUTE = '/admin/purchases';
export const SUPPLIERS_ROUTE = '/admin/suppliers';
export const REPORTS_ROUTE = '/admin/reports';
export const EXPENSES_REPORT_ROUTE = '/admin/reports/expenses-report';
export const PURCHASES_REPORT_ROUTE = '/admin/reports/purchases-report';
export const MENU_ITEMS_REPORT_ROUTE = '/admin/reports/menu-items-report';
export const MENU_ITEMS_OVERTIME_REPORT_ROUTE = '/admin/reports/menu-items-overtime-report';
export const MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT_ROUTE = '/admin/reports/menu-items-sold-by-employees-report';
export const MODIFIER_ITEMS_REPORT_ROUTE = '/admin/reports/modifier-items-report';
export const MODIFIER_ITEMS_OVERTIME_REPORT_ROUTE = '/admin/reports/menu-modifier-items-overtime-report';
export const SALES_REPORT_ROUTE = '/admin/reports/sales-report';
export const INVENTORY_REPORT_ROUTE = '/admin/reports/inventory-report';
export const INVENTORY_OVERTIME_REPORT_ROUTE = '/admin/reports/inventory-overtime-report';
export const EMPLOYEES_REPORT_ROUTE = '/admin/reports/employees-report';
export const ORDERS_REPORT_ROUTE = '/admin/reports/orders-report';
export const CATEGORIES_ROUTE = '/admin/categories';
export const AUTH_RESTAURANTS_ROUTE = '/auth/restaurants';
export const REGISTERS_ROUTE = '/admin/registers';
export const CUSTOMERS_ROUTE = '/admin/customers';

export const restaurantTypes = [
  'CAFETERIA',
  'FINE_DINING',
  'FOOD_TRUCK',
  'BUFFET',
  'CASUAL_DINING',
  'PUB_OR_BAR',
  'DINER',
  'BAKERY',
  'HOTEL',
  'PIZZERIA',
  'BISTRO',
  'FAST_FOOD',
];

export const registerTransactionTypes = [
  'EXPENSE',
  'PURCHASE',
  'PAYMENT',
]

export const diningOptions = [
  { value: '', label: 'None' },
  { value: 'DINE_IN', label: 'Dine In' },
  { value: 'DELIVERY', label: 'Delivery' },
  { value: 'TAKE_OUT', label: 'Takeout' },
]

export const expenseTypes = [
  { value: "RECURRING", label: "Recurring"},
  { value: "NONRECURRING", label: "Non recurring"},
];

export const expenseStatus = [
  { value: "PAID", label: "Paid"},
  { value: "PENDING", label: "Pending"},
];

export const occupancyStatuses = [
  { value: "OPEN", label: "Open" },
  { value: "CLOSED", label: "Closed" },
  { value: "RESERVED", label: "Reserved" },
];

export const paymentStatuses = [
  { value: '', label: 'None' },
  { value: "PAID", label: "Paid" },
  { value: "OVERDUE", label: "Overdue" },
  { value: "UNPAID", label: "Unpaid" },
  { value: "PARTIALLY_PAID", label: "Partially Paid" },
];

export const tableStatus = [
  { value: '', label: 'None' },
  { value: "OCCUPIED", label: "Occupied" },
  { value: "AVAILABLE", label: "Available" },
  { value: "BLOCKED", label: "Blocked" },
  { value: "RESERVED", label: "Reserved" },
  { value: "UNDER_MAINTENANCE", label: "Under Maintenance" },
  { value: "DIRTY", label: "Dirty" },
  { value: "WAITING", label: "Waiting" },
  { value: "SERVED", label: "Served" },
];

export const paymentMethods = [
  { value: '', label: 'None' },
  { value: "CASH", label: "Cash" },
  { value: "CHECK", label: "Check" },
  { value: "BANK_CARD", label: "Bank Card" },
  { value: "WIRE_TRANSFER", label: "Wire Transfer" },
  { value: "MOBILE_MONEY", label: "Mobile Money" }
];

export const timeframes = [
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'today', label: 'Today' },
  { value: 'this_week', label: 'This Week' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'this_month', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'last_3_months', label: 'Last 3 Months' },
  { value: 'last_6_months', label: 'Last 6 Months' },
  { value: 'this_year', label: 'This Year' },
  { value: 'last_year', label: 'Last Year' },
  { value: 'all_time', label: 'All Time' },
]

export const refundMethods = [
  { value: '', label: 'None' },
  { value: "CASH", label: "Cash" },
  { value: "CHECK", label: "Check" },
  { value: "BANK_CARD", label: "Bank Card" },
  { value: "WIRE_TRANSFER", label: "Wire Transfer" },
  { value: "MOBILE_MONEY", label: "Mobile Money" }
];

export const unitsOfMeasure = [
  "",
  'EACH',
  'ITEM',
  'PIECE',
  'KILOGRAM',
  'POUND',
  'GRAM',
  'LITRE',
  'MILLILITRE',
];

export const unitOfMeasureArray = [
  "",
  "EACH",
  "ITEM",
  "PIECE",
  "KILOGRAM",
  "POUND",
  "GRAM",
  "MICROGRAM",
  "NANOGRAM",
  "PICOGRAM",
  "MEGATONNE",
  "GIGATONNE"
];

export const CANCELED = 'CANCELED';
export const PLACED = 'PLACED';
