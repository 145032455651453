import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {head, length, pathOr} from "ramda";
import {Button, Input, InputNumber, message, Select, Space, Switch} from "antd";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../store";
import {SETTINGS_ROUTE} from "../../../../../constants";
import {countries, currencies} from "../../../../../data";
import {validateSchema} from "views/admin/settings/locations/validations/schema"
import {pickFields} from "../../../../../utils/is-not-empty";
import {createLocationAPI, updateLocationAPI} from "../../../../../api/locations";

interface MenuItemFormProps {
  mode: "create" | "edit";
}

const LocationForm: React.FC<MenuItemFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({
    name: "",
    city: "",
    town: "",
    country: "",
    address: "",
    zipcode: "",
    currencyName: "",
    currencySymbol: "",
    currencyIsoCode: "",
    countryCode: "",
    longitude: "",
    latitude: "",
    hoursOfOperation: [],
    timezone: "",
    costPerKilometer: 0,
    coverImageURL: "",
    email: "",
    helpLine: "",
    tiktok: "",
    instagram: "",
    twitter: "",
    facebook: "",
    whatsapp: "",
    orderSalesTaxPercentage: 0,
    shouldChargeSalesTaxOnOrder: false,
  });
  const dispatch = useAppDispatch();
  const location = useAppSelector((state: RootState) => state.locations.location);
  const isLoading = useAppSelector((state: RootState) => state.locations.isLoading)

  useEffect(() => {
    if (location) {
      setFormValues({
        name: pathOr('', ['name'], location),
        latitude: pathOr(0, ['latitude'], location),
        longitude: pathOr(0, ['longitude'], location),
        timezone: pathOr('', ['timezone'], location),
        costPerKilometer: pathOr(0, ['costPerKilometer'], location),
        coverImageURL: pathOr('', ['coverImageURL'], location),
        email: pathOr('', ['email'], location),
        helpLine: pathOr('', ['helpLine'], location),
        tiktok: pathOr('', ['tiktok'], location),
        instagram: pathOr('', ['instagram'], location),
        twitter: pathOr('', ['twitter'], location),
        facebook: pathOr('', ['facebook'], location),
        whatsapp: pathOr('', ['whatsapp'], location),
        hoursOfOperation: pathOr([], ['hoursOfOperation'], location),
        city: pathOr('', ['city'], location),
        town: pathOr('', ['town'], location),
        country: pathOr('', ['country'], location),
        address: pathOr('', ['address'], location),
        zipcode: pathOr('', ['zipcode'], location),
        currencyName: pathOr('', ['currencyName'], location),
        currencySymbol: pathOr('', ['currencySymbol'], location),
        currencyIsoCode: pathOr('', ['currencyIsoCode'], location),
        countryCode: pathOr('', ['countryCode'], location),
        orderSalesTaxPercentage: pathOr(0, ['orderSalesTaxPercentage'], location),
        shouldChargeSalesTaxOnOrder: pathOr(false, ['shouldChargeSalesTaxOnOrder'], location),
      })
    }
  }, [location]);

  const handleChange = (value: any, name: string, type?: 'location' | 'country' | 'currency') => {
    if (type === 'country') {
      const country: any = head(countries.filter(
         (count: {name: string; code: string;}) => count.name === value));

      setFormValues({
        ...formValues,
        country: pathOr('', ['name'], country),
        countryCode: pathOr('', ['code'], country),
        timezone: pathOr('', ['timezone'], country),
      })
      return;
    }

    if (type === 'currency') {
      setFormValues({
        ...formValues,
        currencyName: pathOr('', [value, 'name'], currencies),
        currencySymbol: pathOr('', [value, 'symbol_native'], currencies),
        currencyIsoCode: pathOr('', [value, 'code'], currencies),
      })
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value
    })
    return;
  }
  const onFinish = async () => {
    const locationsObj = { 
      ...pickFields(formValues, 
        [
          'name',
          'longitude',
          'latitude',
          'hoursOfOperation',
          'timezone',
          'costPerKilometer',
          'coverImageURL',
          'email',
          'helpLine',
          'city',
          'town',
          'country',
          'address',
          'zipcode',
          'currencyName',
          'currencySymbol',
          'currencyIsoCode',
          'countryCode',
          'orderSalesTaxPercentage',
          'shouldChargeSalesTaxOnOrder',
        ]
      ) 
    };
    const { errors } = validateSchema(locationsObj);
    setErrors(errors);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return
    }

    if (!pathOr(null, ['id'], location)) {
      await dispatch(createLocationAPI({location: locationsObj, restaurantId: id}));
    } else {
      await dispatch(updateLocationAPI({
        location: locationsObj,
        locationId: pathOr('', ['id'], location)
      }))
    }
  };
  const handleHoursOfOperation= (value: string | string[]) => {
    setFormValues({
      ...formValues,
      hoursOfOperation: value
    })
  };
  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormValues({
            ...formValues,
            latitude,
            longitude,
          });
        },
        (err) => {
          message.error(err.message);
        },
      );
    } else {
      message.error('Geolocation is not supported by this browser.');
    }
  }
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Location</p>
          <p className="text-base text-gray-750">
            {pathOr('', ['id'], location)  ? `Edit Location` : "Create Location"}
          </p>
        </div>

        <div>
          <Space>
            <div>
              <Button className="bg-blue-750" loading={isLoading} type="primary" onClick={onFinish}>
                {pathOr('', ['id'], location) ? 'Update Location' : 'Create Location'}
              </Button>
            </div>

            <div>
              <Button onClick={() => navigate(SETTINGS_ROUTE)}>Cancel</Button>
            </div>
          </Space>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row items-center justify-between">
            <div>
              <Button onClick={getCoordinates}>Get Coordinates</Button>
            </div>

            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Charge sales tax on order</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ["shouldChargeSalesTaxOnOrder"], formValues)}
                    onChange={(e) => handleChange(e, "shouldChargeSalesTaxOnOrder")}
                  />
                </div>
              </div>
            </div>
            
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Location Name<span className='text-red-500'>*</span></span>
              <Input
                 name="locationName"
                 required
                 status={errors['Location name'] ? 'error' : ''}
                 value={pathOr('', ['name'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'name',
                    'location'
                 )}
              />
              { errors['Location name'] ? <span className='text-red-500'>
              {pathOr('', ['Location name', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Country<span className='text-red-500'>*</span></span>
              <Select
                 className="w-full"
                 status={errors['Country'] ? 'error' : ''}
                 onChange={(val) => handleChange(val, 'country', 'country')}
                 options={countries.map((country: {name: string; code: string;}) => {
                   return {
                     label: country.name,
                     value: country.name,
                   }
                 })}
                 value={pathOr('', ['country'], formValues)}
              />
              { errors['Location country'] ? <span className='text-red-500'>
              {pathOr('', ['Location country', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Currency<span className='text-red-500'>*</span></span>
              <Select
                 className="w-full"
                 status={errors['Location currency name'] ? 'error' : ''}
                 onChange={(val) => handleChange(val, 'currency', 'currency')}
                 options={Object.keys(currencies).map((currency) => {
                   return {
                     label: `${pathOr('', [currency, 'name'], currencies)}`,
                     value: currency
                   }
                 })}
                 value={pathOr('', ['currencyName'], formValues)}
              />
              { errors['Location currency name'] ? <span className='text-red-500'>
              {pathOr('', ['Location currency name', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Address<span className='text-red-500'>*</span></span>
              <Input
                 name="address"
                 className="w-full"
                 status={errors['Location address'] ? 'error' : ''}
                 value={pathOr('', ['address'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'address',

                 )}
              />
              { errors['Location address'] ? <span className='text-red-500'>
              {pathOr('', ['Location address', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>City<span className='text-red-500'>*</span></span>
              <Input
                 name="city"
                 className="w-full"
                 status={errors['Location city'] ? 'error' : ''}
                 value={pathOr('', ['city'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'city',
                 )}
              />
              { errors['Location city'] ? <span className='text-red-500'>
              {pathOr('', ['Location city', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Town<span className='text-red-500'>*</span></span>
              <Input
                 name="town"
                 status={errors['Location town'] ? 'error' : ''}
                 value={pathOr('', ['town'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'town',
                 )}
              />
              { errors['Location town'] ? <span className='text-red-500'>
              {pathOr('', ['Location town', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div className="w-[32%]">
              <span>Zipcode<span className='text-red-500'>*</span></span>
                <Input
                  name="zipcode"
                  status={errors['Location zipcode'] ? 'error' : ''}
                  value={pathOr('', ['zipcode'], formValues)}
                  required
                  onChange={(e) => handleChange(
                      e.target.value,
                      'zipcode',
                  )}
                />
                { errors['Location zipcode'] ? <span className='text-red-500'>
                {pathOr('', ['Location zipcode', 0, 'message'], errors)}
              </span> : null}
            </div>

            <div className="w-[32%]">
              <span>Latitude</span>
                <InputNumber
                  className="w-full"
                  name="latitude"
                  status={errors['Location latitude'] ? 'error' : ''}
                  value={pathOr('', ['latitude'], formValues)}
                  required
                  onChange={(val) => handleChange(
                    val,
                    'latitude',
                  )}
                />
                { errors['Location latitude'] ? <span className='text-red-500'>
                {pathOr('', ['Location latitude', 0, 'message'], errors)}
              </span> : null}
            </div>

            <div className="w-[32%]">
              <span>Longitude</span>
                <InputNumber
                  className="w-full"
                  name="longitude"
                  status={errors['Location longitude'] ? 'error' : ''}
                  value={pathOr('', ['longitude'], formValues)}
                  required
                  onChange={(val) => handleChange(
                      val,
                      'longitude',
                  )}
                />
                { errors['Location longitude'] ? <span className='text-red-500'>
                {pathOr('', ['Location longitude', 0, 'message'], errors)}
              </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Hours Of Operation</span>
              <Select
                mode="tags"
                allowClear
                className="w-full"
                onChange={handleHoursOfOperation}
                value={pathOr([], ['hoursOfOperation'], formValues)}
                status={pathOr(null, ["Location hours of operation"], errors) ? "error" : ""}
                options={pathOr([], ["hoursOfOperation"], formValues).map((hour: any) => {
                  return {
                    label: hour,
                    value: hour,
                  };
                })}
              />
              { errors['Location hours of operation'] ? <span className='text-red-500'>
              {pathOr('', ['Location hours of operation', 0, 'message'], errors)}
              </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Helpline</span>
              <Input
                 name="helpline"
                 status={errors['Location helpline'] ? 'error' : ''}
                 value={pathOr('', ['helpLine'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'helpLine',
                 )}
              />
              { errors['Location helpline'] ? <span className='text-red-500'>
              {pathOr('', ['Location helpline', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Email</span>
              <Input
                 name="email"
                 status={errors['Location email'] ? 'error' : ''}
                 value={pathOr('', ['email'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'email',
                 )}
              />
              { errors['Location email'] ? <span className='text-red-500'>
              {pathOr('', ['Location email', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div className="w-[48%]">
              <span>Order Sales Tax Percentage</span>
                <InputNumber
                  className="w-full"
                  name="orderSalesTaxPercentage"
                  status={errors['Order Sales Tax Percentage'] ? 'error' : ''}
                  value={pathOr(0, ['orderSalesTaxPercentage'], formValues)}
                  onChange={(val: any) => handleChange(
                    val,
                    'orderSalesTaxPercentage',
                  )}
                />
                { errors['Order sales tax percentage'] ? <span className='text-red-500'>
                {pathOr('', ['Order sales tax percentage', 0, 'message'], errors)}
              </span> : null}
            </div>

            <div className="w-[48%]">
              <span>Cost Per Kilometer</span>
                <InputNumber
                  className="w-full"
                  name="costPerKilometer"
                  status={errors['Location cost per kilometer'] ? 'error' : ''}
                  value={pathOr(0, ['costPerKilometer'], formValues)}
                  onChange={(val: any) => handleChange(
                    val,
                    'costPerKilometer',
                  )}
                />
                { errors['Location cost per kilometer'] ? <span className='text-red-500'>
                {pathOr('', ['Location cost per kilometer', 0, 'message'], errors)}
              </span> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationForm;
