import { Button, Input, InputNumber, Select } from "antd";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { length, pathOr, replace } from "ramda";
import { updateOrder } from "../../../store/orders/orders";
import OrderPaymentModal from "./components/order-payment-modal";
import OrderRefundModal from "./components/order-refund-modal";
import { PLACED } from "../../../constants";
import { CANCELED } from "../../../constants";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_ISSUE_REFUND, CAN_PAY_ORDER } from "permissions";

const OrderDetailsForm = () => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<string>("");
  const order = useAppSelector((state: any) => state.orders.order);
  const orderStages = useAppSelector((state: any) => state.orders.orderStages);
  const employees = useAppSelector((state: any) => state.employees.employees);
  const tables = useAppSelector((state: any) => state.floors.tables);
  const diningOptions = useAppSelector((state: any) => state.orders.diningOptions);
  const totalAmountRefundable = pathOr(0, ['transaction', 'amountRefundable'], order);
  const amountDue = pathOr(0, ['transaction', 'amountDue'], order);

  const onChange = (val: any, type: string) => {
    dispatch(
      updateOrder({
        [type]: val,
      }),
    );
  };
  const closeModal = () => {
    setModal("");
  };

  const cancelOrder = () => {
    dispatch(
      updateOrder({
        isCanceled: true,
        stage: CANCELED
      }),
    );
  }

  const placeOrder = () => {
    dispatch(
      updateOrder({
        isCanceled: false,
        stage: PLACED
      }),
    );
  }

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-2 justify-end">
            {totalAmountRefundable > 0 ? <div>
              <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_ISSUE_REFUND]}>
                <Button onClick={() => setModal('refundModal')}>Refund</Button>
              </ProtectedWrapper>
            </div> : null}
            {
             amountDue > 0 && length(pathOr([], ['orderItems'], order)) > 0 ?  <div>
                <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_PAY_ORDER]}>
                  <Button onClick={() => setModal('paymentModal')}>Pay</Button>
                </ProtectedWrapper>
              </div> : null
            }
            {pathOr(false, ['isCanceled'], order) ? <div>
              <Button onClick={placeOrder}>Place Order</Button>
            </div> : <div>
              <Button onClick={cancelOrder}>Cancel Order</Button>
            </div>}
          </div>
          <div className="w-full flex flex-wrap flex-row items-center justify-between">
            <div className="w-full md:[48%] lg:w-[32%]">
              <span>Stage</span>
              <Select
                className="w-full"
                value={pathOr("", ["stage"], order).toLowerCase()}
                onSelect={(val) => onChange(val, "stage")}
                options={orderStages.map((orderStage: { name: string; value: string }) => {
                  return {
                    label: pathOr("", ["name"], orderStage),
                    value: pathOr("", ["value"], orderStage).toLowerCase(),
                  };
                })}
              />
            </div>

            <div className="w-full md:[48%] lg:w-[32%]">
              <span>Dining Option</span>
              <Select
                className="w-full"
                onSelect={(val) => onChange(val, "type")}
                options={diningOptions.map((diningOption: string) => {
                  return {
                    label: replace(/_/g, " ", diningOption),
                    value: diningOption,
                  };
                })}
                value={pathOr("", ["type"], order)}
              />
            </div>

            <div className="w-full md:[48%] lg:w-[32%]">
              <span>Server</span>
              <Select
                value={pathOr("", ["serverId"], order)}
                className="w-full"
                onChange={(val) => onChange(val, "serverId")}
                options={employees.map((employee: any) => {
                  return {
                    label: `${pathOr("", ["user", "profile", "firstname"], employee)} 
                       ${pathOr("", ["user", "profile", "lastname"], employee)} (
                       ${pathOr("", ["role"], employee)}
                       )`,
                    value: pathOr("", ["user", "id"], employee),
                  };
                })}
              />
            </div>
          </div>

          <div>
            <span>Table</span>
            <Select
              onSelect={(val) => onChange(val, "tableId")}
              options={tables.map((table: any) => {
                return {
                  label: `${pathOr("", ["tableNumber"], table)} (
                   ${pathOr("", ["status"], table)}
                   )`,
                  value: pathOr("", ["id"], table),
                };
              })}
              value={pathOr("", ["tableId"], order)}
              className="w-full"
            />
          </div>

          <div className="w-full flex flex-row items-center justify-between">
            <div style={{ width: "32%" }}>
              <span>Discount</span>
              <InputNumber
                onChange={(val) => onChange(Number(val), "discountAmount")}
                value={pathOr(0, ["discountAmount"], order)}
                className="w-full"
              />
            </div>

            <div style={{ width: "32%" }}>
              <span>Tip</span>
              <InputNumber
                onChange={(val) => onChange(Number(val), "tipAmount")}
                value={pathOr(0, ["tipAmount"], order)}
                className="w-full"
              />
            </div>

            <div style={{ width: "32%" }}>
              <span>Guests</span>
              <InputNumber
                onChange={(val) => onChange(Number(val), "guests")}
                value={pathOr(0, ["guests"], order)}
                className="w-full"
              />
            </div>
          </div>

          <div>
            <span>Kitchen Note</span>
            <div>
              <Input.TextArea
                onChange={(e) => onChange(e.target.value, "kitchenNote")}
                rows={3}
                value={pathOr(0, ["kitchenNote"], order)}
              />
            </div>
          </div>
        </div>
      </div>
      <OrderPaymentModal isOpen={modal === "paymentModal"} onClose={closeModal} />
      <OrderRefundModal isOpen={modal === "refundModal"} onClose={closeModal} />
    </div>
  );
};

export default OrderDetailsForm;
