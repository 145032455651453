import { MdRestaurant } from "react-icons/md";

import Widget from "components/widget/Widget";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchLocationStatsAPI } from "api/reports/stats";
import { AppDispatch, RootState } from "store";
import { pathOr } from "ramda";
import { Select } from "antd";
import { timeframes } from "../../../../constants";
import { getTimeframeDates } from "helpers/utilities";
import { GiBuyCard, GiExpense, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { FaMoneyBillTransfer } from "react-icons/fa6";
;

const Widgets = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const [timeframe, setTimeframe] = useState<string>('today');

    useEffect(() => {
        const { fromDate, toDate } = getTimeframeDates(timeframe);
        dispatch(fetchLocationStatsAPI({ fromDate, toDate, refresh: true }));
    }, [dispatch, timeframe])

    const handleChange = async (val: any) => {
      setTimeframe(val);
      const { fromDate, toDate } = getTimeframeDates(val);
      await dispatch(fetchLocationStatsAPI({fromDate, toDate, refresh: true}));
    }

    const currencySymbol = useAppSelector(
        (state: RootState) => pathOr('', ['currencySymbol'], state.auth.currentLocation));
    const stats = useAppSelector((state: RootState) => state.stats.stats);
    const revenue = pathOr(0, ['actualRevenue'], stats);
    const totalRefunds = pathOr(0, ['totalRefunds'], stats);
    const lostRevenue = pathOr(0, ['lostRevenue'], stats);
    const totalOrders = pathOr(0, ['totalOrders'], stats);
    const totalExpenseAmount = pathOr(0, ['totalExpenseAmount'], stats);
    const totalPurchaseAmount = pathOr(0, ['totalPurchaseAmount'], stats);

    return (
        <div>
            <div>
                <div>
                    <Select
                        style={{ width: 150 }}
                        onChange={handleChange}
                        value={timeframe}
                        options={timeframes.map((time) => {
                            return {
                                label: pathOr('', ['label'], time),
                                value: pathOr('', ['value'], time)
                            }
                        })}
                    />
                </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2
             lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
                <Widget
                    icon={<GiTakeMyMoney className="h-7 w-7" />}
                    title={"Revenue"}
                    subtitle={`${currencySymbol} ${revenue.toLocaleString()}`}
                />
                <Widget
                    icon={<GiPayMoney className="h-7 w-7" />}
                    title={"Lost Revenue"}
                    subtitle={`${currencySymbol} ${lostRevenue.toLocaleString()}`}
                />
                <Widget
                    icon={<FaMoneyBillTransfer className="h-7 w-7" />}
                    title={"Refunds"}
                    subtitle={`${currencySymbol} ${totalRefunds.toLocaleString()}`}
                />
                <Widget
                    icon={<GiExpense className="h-6 w-6" />}
                    title={"Expenses"}
                    subtitle={`${currencySymbol} ${totalExpenseAmount.toLocaleString()}`}
                />
                <Widget
                    icon={<GiBuyCard className="h-6 w-6" />}
                    title={"Purchases"}
                    subtitle={`${currencySymbol} ${totalPurchaseAmount.toLocaleString()}`}
                />
                <Widget
                    icon={<MdRestaurant className="h-6 w-6" />}
                    title={"Total Orders"}
                    subtitle={`${totalOrders}`}
                />
            </div>
        </div>
        
    )
}

export default Widgets;
