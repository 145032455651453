import React, { useState, useEffect } from "react";
import { Form, Spin, Flex } from "antd";
import { pathOr } from "ramda";
import { BusinessTypes } from "../../../../types/AllTypes";
import { CustomButton } from "components/customButton/customButton";
import FormInputRenderer from "components/formInputRenderer/FormInputRenderer";
import { handleValidationErrors, validateFormFieldsOne } from "helpers/utilities";
import { updateCurrentBusiness } from "../../../../store/business/bussiness";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";


const BusinessSettings = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: RootState) => state.business.isLoading);
  const isSubmitting = useAppSelector((state: RootState) => state.business.isSubmitting);
  const business = useAppSelector((state: RootState) => state.auth.business);
  const [formValues, setFormValues] = useState<BusinessTypes | any>({
    name: "",
  });

  useEffect(() => {
    setFormValues({
      name: pathOr("", ["name"], business),
    });
  }, [business]);

  const handleInputChange = (name: any, type: string | undefined) => (value: any) => {
    const processedValue = type === "number" ? parseFloat(value) || 0 : value;

    setFormValues((prev: any) => ({
      ...prev,
      [name]: processedValue,
    }));
  };

  const renderFormInputs = (
    inputs: {
      label: string;
      name: any;
      required?: boolean;
      type?: string;
      styling?: string;
      placeholder?: string;
    }[],
  ) =>
    inputs.map((input) => (
      <FormInputRenderer
        key={input.name}
        label={input.label}
        type={input.type}
        name={input.name as string}
        required={input.required}
        styling={input.styling}
        placeholder={input.placeholder}
        value={formValues[input.name as keyof BusinessTypes]}
        onChange={handleInputChange(input.name, input.type)}
      />
    ));

  const onFinish = async () => {
    const newErrors = validateFormFieldsOne(formValues);

    if (handleValidationErrors(form, newErrors)) {
      return;
    }
    try {
      dispatch(updateCurrentBusiness(formValues));
    } catch (error: any) {}
  };

  return (
    <div>
      <div className="mb-6">
        <p className="text-base font-medium">Business</p>
        <p className="text-sm text-gray-750">View and update your business settings.</p>
      </div>
      <div className="mb-8 pb-8">
        {isLoading}
        <Form layout="vertical" form={form} className="w-full">
          {isLoading ? (
            <Flex align="center" justify="center" className="pt-7">
              <Spin size="large" />
            </Flex>
          ) : (
            <>
              <div className="flex -m-2">
                {renderFormInputs([
                  {
                    label: "Name",
                    name: "name",
                    required: true,
                    styling: "grow",
                  },
                ])}
                <div className=" p-2 pt-9">
                  <CustomButton
                    type="primary"
                    onClick={onFinish}
                    label="Update"
                    disabled={isSubmitting}
                    submitting={isSubmitting}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -m-2"></div>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default BusinessSettings;
