import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setupAccountAPI,
  signInWithEmailAndPasswordAPI,
  signOutAPI, signUpAPI, updateUserAccountAPI
} from "../../api/auth";
import {fetchCurrentUserAPI} from "../../api/users";
import {clearAllLocalStorage, deleteAllCookies, getCookie, setCookie} from "helpers/utilities";
import {head, length, pathOr} from "ramda";
import { message } from "antd";
import {AUTH_RESTAURANTS_ROUTE, AUTH_SIGN_IN_ROUTE, DASHBOARD_ROUTE, SETTINGS_ROUTE} from "../../constants";
import {createRestaurantAPI} from "../../api/restaurants";

interface AuthProps {
  user: any;
  currentRestaurant: any,
  currentLocation: any,
  isAccountSetup: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  business: any;
}

const initialState: AuthProps = {
  user: null,
  currentRestaurant: null,
  currentLocation: null,
  isAccountSetup: false,
  isLoading: false,
  isSubmitting: false,
  business: null,
};

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
      setCookie("currentLocation", pathOr("", ["id"], action.payload), 1);
      window.location.href = SETTINGS_ROUTE
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    setIsAccountSetup: (state, action: PayloadAction<boolean>) => {
      state.isAccountSetup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUserAPI.fulfilled, (state, action) => {
      const currentRestaurantId = getCookie('currentRestaurant');
      const currentLocationId = getCookie('currentLocation');
      const user = pathOr(null, ['user'], action.payload);
      state.currentRestaurant = head(pathOr([], ['employee', 'business', 'restaurants'], user).filter((restaurant) =>
         pathOr(null, ['id'], restaurant) === currentRestaurantId));
      state.currentLocation = head(pathOr([], ['locations'], state.currentRestaurant).filter((location) =>
         pathOr(null, ['id'], location) === currentLocationId));
      state.business = pathOr('', ['employee', 'business'], user);
      state.user = user;
      state.isLoading = false;
    });
    builder.addCase(fetchCurrentUserAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchCurrentUserAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signInWithEmailAndPasswordAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      const isAccountSetup = pathOr(false, ["user", "isAccountSetup"], action.payload);
      const refreshToken = pathOr("", ["tokens", "refreshToken"], action.payload);
      const accessToken = pathOr("", ["tokens", "accessToken"], action.payload);
      const user = pathOr(null, ['user'], action.payload);
      const restaurants = pathOr([], ['user', 'employee', 'business', 'restaurants'], action.payload)

      // Dispatch user data to Redux store
      setCookie("accessToken", accessToken, 1);
      setCookie("refreshToken", refreshToken, 1);
      setCookie("isAccountSetup", isAccountSetup.toString(), 1);
      state.user = user;

      if (length(restaurants) > 0) {
        const currentRestaurant = pathOr(
          null,
          [0],
          restaurants,
        );
        const currentLocation = pathOr(null, ["locations", 0], currentRestaurant);

        // Save currentRestaurantId and currentLocationId in cookies
        setCookie("currentRestaurant", pathOr("", ["id"], currentRestaurant), 1);
        setCookie("currentLocation", pathOr("", ["id"], currentLocation), 1);
        state.currentRestaurant = currentRestaurant;
        state.currentLocation = currentLocation;
        state.business = pathOr(null, ['user', 'employee', 'business'], action.payload);
        window.location.href = DASHBOARD_ROUTE;
      } else {
        window.location.href = `${AUTH_RESTAURANTS_ROUTE}/create`;
      }
    });
    builder.addCase(signInWithEmailAndPasswordAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(signInWithEmailAndPasswordAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signUpAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      const refreshToken = pathOr("", ["tokens", "refreshToken"], action.payload);
      const accessToken = pathOr("", ["tokens", "accessToken"], action.payload);
      const user = pathOr(null, ['user'], action.payload);

      // Dispatch user data to Redux store
      setCookie("accessToken", accessToken, 1);
      setCookie("refreshToken", refreshToken, 1);

      state.user = user;
      state.isLoading = false;
      window.location.href = `${AUTH_RESTAURANTS_ROUTE}/create`;
    });
    builder.addCase(signUpAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(signUpAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRestaurantAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.isLoading = false;
      state.currentRestaurant = pathOr(null, ['restaurant'], action.payload);
      state.currentLocation = pathOr(null, ['locations', 0], state.currentRestaurant);
      setCookie("currentRestaurant", pathOr("", ["id"], state.currentRestaurant), 1);
      setCookie("currentLocation", pathOr("", ["id"], state.currentLocation), 1);
      window.location.href = DASHBOARD_ROUTE;
    });
    builder.addCase(createRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(setupAccountAPI.fulfilled, (state, action) => {
      const isAccountSetup = pathOr(false, ["user", "isAccountSetup"], action.payload);
      const currentRestaurant = pathOr(null,
         ["user", "employee", "business", "restaurants", 0],
         action.payload,
      );
      const currentLocation = pathOr(null, ["locations", 0], currentRestaurant);
      setCookie("currentRestaurant", pathOr("", ["id"], currentRestaurant), 1);
      setCookie("currentLocation", pathOr("", ["id"], currentLocation), 1);
      setCookie("isAccountSetup", String(isAccountSetup), 1);
      state.currentRestaurant = currentRestaurant;
      state.currentLocation = currentLocation;
      state.isAccountSetup = isAccountSetup;
      state.isLoading = false;
    });
    builder.addCase(setupAccountAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(setupAccountAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(signOutAPI.fulfilled, (state, action) => {
      clearAllLocalStorage();
      deleteAllCookies()
      state.isLoading = false;
      window.location.href = AUTH_SIGN_IN_ROUTE;
    });
    builder.addCase(signOutAPI.rejected, (state, action) => {
      clearAllLocalStorage();
      deleteAllCookies()
      state.isLoading = false;
      window.location.href = AUTH_SIGN_IN_ROUTE;
    });
    builder.addCase(signOutAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserAccountAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.isLoading = false;
    });
    builder.addCase(updateUserAccountAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserAccountAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setUserData,
  setIsAccountSetup,
  setCurrentLocation
} = authSlice.actions;
export default authSlice.reducer;
