import React, { useState } from "react";
import { Link } from "react-router-dom";
import {Button, Form} from "antd";
import { FormInput } from "components/formInput/FormInput";
import {useAppDispatch, useAppSelector} from "store/hooks";
import Footer from "components/footer/Footer";
import authImg2 from "assets/img/auth/auth2.jpg";
import { SignInTypes } from "types/UserTypes";
import {signInWithEmailAndPasswordAPI} from "../../../api/auth";

const SignIn = () => {
  const [signInError, setSignInError] = useState(false);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.auth.isLoading);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<SignInTypes>({
    email: "",
    password: "",
  });

  const handleChange = (name: string) => (value: string) =>
    setFormValues((prev) => ({ ...prev, [name]: value }));

  const onFinish = async () => {
    await dispatch(signInWithEmailAndPasswordAPI({ user: formValues }));
  };

  const inputConfigs = [
    { label: "Email", name: "email", placeholder: "", isRequired: true },
    { label: "Password", name: "password", isPassword: true, isRequired: true },
  ];
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <Link to="/auth/sign-up" className="mt-0 w-max lg:pt-10">
                  <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                        fill="#A3AED0"
                      />
                    </svg>
                    <p className="ml-3 text-base text-gray-600">
                      Sign up
                    </p>
                  </div>
                </Link>
                <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                  <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                      Sign In
                    </h4>
                    <p className="mb-4 ml-1 text-base text-gray-600">
                      Enter your email and password to sign in!
                    </p>
                    {signInError && (
                      <p className="text-sm text-red-400 mb-4">Wrong Email or Password</p>
                    )}
                    <Form layout="vertical" form={form} className="w-full">
                      {inputConfigs.map((config) => (
                        <div key={config.name} className="w-full">
                          <FormInput
                            {...config}
                            value={formValues[config.name]}
                            onChange={handleChange(config.name)}
                          />
                        </div>
                      ))}
                      <div className="pt-2">
                        <Form.Item>
                          <Button
                             loading={isLoading}
                             onClick={onFinish}
                             type="primary"
                             className="bg-blue-750">Sign In</Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg2})` }}
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SignIn;
