import { Routes, Route, Navigate } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import AdminLayout from 'layouts/admin';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SignUp from 'views/auth/sign-up/sign-up';
import SignIn from 'views/auth/sign-in/sign-in';
import { getCookie } from './helpers/utilities';
import {AUTH_RESTAURANTS_ROUTE, AUTH_SIGN_IN_ROUTE, AUTH_SIGN_UP_ROUTE} from "./constants";
import CreateRestaurant from "views/auth/sign-up/components/create-restaurant";
import { CAN_ACCESS_DASHBOARD } from 'permissions';
import { useAppDispatch } from 'store/hooks';
import { useEffect } from 'react';
import { fetchCurrentUserAPI } from 'api/users';

ChartJS.register(ChartDataLabels);
ChartJS.register(...registerables);

const App = () => {
  const dispatch = useAppDispatch();
  const accessToken = getCookie('accessToken');
  const restaurantId = getCookie('currentRestaurant');
  const locationId = getCookie('currentLocation');

  useEffect(() => {
    dispatch(fetchCurrentUserAPI())
  }, [dispatch])

  return (
    <Routes>
      {/* For authenticated users with an account set up, render the AdminLayout */}

      {accessToken && restaurantId && locationId ? (
        <Route path="/admin/*" element={<AdminLayout permissionsNeeded={[CAN_ACCESS_DASHBOARD]} />} />
      ) : null}

      {accessToken && !locationId && !restaurantId &&  (
          <Route path={`${AUTH_RESTAURANTS_ROUTE}/create`} element={<CreateRestaurant />} />
        )
      }

      <Route path={AUTH_SIGN_UP_ROUTE} element={<SignUp />} />
      <Route path={AUTH_SIGN_IN_ROUTE} element={<SignIn />} />

      {/* For all other routes, redirect to /auth/sign-up if not authenticated, otherwise /admin */}
      <Route
        path="/*"
        element={accessToken && locationId && restaurantId ? (
          <Navigate to="/admin" replace />
        ) : (
          <Navigate to={AUTH_SIGN_IN_ROUTE} replace />
        )}
      />
    </Routes>
  );
};

export default App;
