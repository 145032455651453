import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Input, message, Select } from "antd";
import { EmployeeTypes } from "types/AllTypes";
import {
  removeUndefinedOrNull,
} from "helpers/utilities";
import { useNavigate, useParams } from "react-router-dom";
import { length, pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {EMPLOYEES_ROUTE} from "../../../../constants";
import {fetchPermissionSetsByBusinessAPI} from "../../../../api/permissions";
import {createEmployeeAPI, fetchEmployeeAPI, updateEmployeeAPI} from "../../../../api/employees";
import {fetchLocationsByRestaurantAPI} from "../../../../api/locations";
import { Link } from "react-router-dom";
import { pickFields } from "utils/is-not-empty";
import { validateSchema } from "../validations/schema";
import { clearSuccessAndError } from "store/employees/employees";

interface EmployeeFormProps {
  mode: "create" | "edit";
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.employees.isLoading);
  const success = useAppSelector((state: any) => state.employees.success);
  const error = useAppSelector((state: any) => state.employees.error);
  const employee = useAppSelector((state: any) => state.employees.employee);
  const permissionSets = useAppSelector((state: any) => state.permissions.permissionSets);
  const locations = useAppSelector((state: any) =>
     pathOr([], ['locations'], state.locations));
  const [errors, setErrors] = useState<any>({});
  const [formValues, setFormValues] = useState<EmployeeTypes | any>({
    firstname: "",
    lastname: "",
    phoneNumber: "",
    email: "",
    passcode: "",
    password: "",
    salary: 0,
    employeeRole: "",
    permissionSetId: "",
    locations: [],
  });

  useEffect(() => {
    if (success) {
      dispatch(clearSuccessAndError());
      navigate(EMPLOYEES_ROUTE);
    }

    if (error) {
      dispatch(clearSuccessAndError());
    }
  }, [success, error, dispatch, navigate])

  useEffect(() => {
    dispatch(fetchPermissionSetsByBusinessAPI({}));
    dispatch(fetchLocationsByRestaurantAPI());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchEmployeeAPI({employeeId: id}));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && employee) {
      const locs: any[] = [];
      pathOr([], ['business', 'restaurants'], employee).map((restaurant) => {
        const locations = pathOr([], ['locations'], restaurant);
        return locations.map((location) => {
          locs.push(
             {
               locationId: pathOr('', ['id'], location),
               restaurantId: pathOr('', ['restaurantId'], location),
             }
          );
          return {
            locationId: pathOr('', ['id'], location),
            restaurantId: pathOr('', ['restaurantId'], location),
          }
        })
      });
      setFormValues({
        firstname: pathOr("", ["user", "profile", "firstname"], employee),
        lastname: pathOr("", ["user", "profile", "lastname"], employee),
        phoneNumber: pathOr("", ["user", "phoneNumber"], employee),
        email: pathOr("", ["user", "email"], employee),
        employeeRole: pathOr("", ["role"], employee),
        salary: pathOr(0, ["salary"], employee),
        permissionSetId: pathOr(0, ["permissionSetId"], employee),
        locations: locs,
      });
    }
  }, [employee, id]);

  const handleChange = (val: any, key: string) => {
    if (key === 'locations') {
      setFormValues({
        ...formValues,
        locations: locations.filter(
          (location) => val.includes(pathOr('', ['id'], location))).map(
            (location) => {
              return {
                locationId: pathOr('', ['id'], location),
                restaurantId: pathOr('', ['restaurantId'], location)
              }
            }),
      })
    } else {
      setFormValues({
        ...formValues,
        [key]: val,
      })
    }
  };

  const onFinish = async () => {
    setErrors({});
    const empPayload: any = removeUndefinedOrNull({
      ...pickFields(formValues, [
      "firstname",
      "lastname",
      "phoneNumber",
      "email",
      "employeeRole",
      "permissionSetId",
      "locations",
      "passcode",
      "password"
    ])});
    const { errors: errorsFromSchema } = validateSchema(empPayload);
    setErrors(errorsFromSchema);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return
    }
    if (id) {
      await dispatch(updateEmployeeAPI({employeeId: id, employee: empPayload}));
    } else {
      await dispatch(createEmployeeAPI({employee: empPayload}));
    }
  };

  const breadcrumbItems = [
    { title: <Link to={EMPLOYEES_ROUTE}>Employees</Link> },
    { title: <span>{mode === "edit" ? `Edit employee` : "Add employee"}</span> },
  ];

  return (
     <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Employees</p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
        <div>
          <div className="flex flex-wrap -m-2">
            <div className="p-2">
              <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={isLoading}
                className="bg-blue-750 w-[150px]">
                {id ? "Update employee" : "Create employee"}
              </Button>
            </div>
          </div>
        </div>
      </div>
       <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-4">
          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>
                First name<span className="text-red-500">*</span>
              </span>
              <Input
                name="firstname"
                required
                status={pathOr(null, ["Firstname"], errors) ? "error" : ""}
                value={pathOr("", ["firstname"], formValues)}
                onChange={(e) => handleChange(e.target.value, "firstname")}
              />
              {pathOr(null, ["Firstname"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Firstname", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Last name<span className="text-red-500">*</span>
              </span>
              <Input
                name="firstname"
                required
                status={pathOr(null, ["Lastname"], errors) ? "error" : ""}
                value={pathOr("", ["lastname"], formValues)}
                onChange={(e) => handleChange(e.target.value, "lastname")}
              />
              {pathOr(null, ["Lastname"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Lastname", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Phone number<span className="text-red-500">*</span>
              </span>
              <Input
                name="phonenumber"
                required
                status={pathOr(null, ["PhoneNumber"], errors) ? "error" : ""}
                value={pathOr("", ["phoneNumber"], formValues)}
                onChange={(e) => handleChange(e.target.value, "phoneNumber")}
              />
              {pathOr(null, ["PhoneNumber"], errors) ? (
                <span className="text-red-500">{pathOr("", ["PhoneNumber", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>

          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>
                Email<span className="text-red-500">*</span>
              </span>
              <Input
                name="email"
                required
                status={pathOr(null, ["email"], errors) ? "error" : ""}
                value={pathOr("", ["email"], formValues)}
                onChange={(e) => handleChange(e.target.value, "email")}
              />
              {pathOr(null, ["Email"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Email", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Password
              </span>
              <Input
                name="password"
                status={pathOr(null, ["Password"], errors) ? "error" : ""}
                value={pathOr("", ["password"], formValues)}
                onChange={(e) => handleChange(e.target.value, "password")}
              />
              {pathOr(null, ["Password"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Password", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Passcode
              </span>
              <Input
                name="passcode"
                status={pathOr(null, ["Passcode"], errors) ? "error" : ""}
                value={pathOr("", ["passcode"], formValues)}
                onChange={(e) => handleChange(e.target.value, "passcode")}
              />
              {pathOr(null, ["Passcode"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Passcode", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>

          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>
                Employee Role<span className="text-red-500">*</span>
              </span>
              <Input
                name="employeeRole"
                required
                status={pathOr(null, ["EmployeeRole"], errors) ? "error" : ""}
                value={pathOr("", ["employeeRole"], formValues)}
                onChange={(e) => handleChange(e.target.value, "employeeRole")}
              />
              {pathOr(null, ["EmployeeRole"], errors) ? (
                <span className="text-red-500">{pathOr("", ["EmployeeRole", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Permission Set<span className="text-red-500">*</span>
              </span>
              <Select
                options={permissionSets.map((permission: any) => ({
                  value: permission.id,
                  label: pathOr('', ['name'], permission).toUpperCase(),
                    })
                )}
                className="w-full"
                status={pathOr(null, ["PermissionSet"], errors) ? "error" : ""}
                value={pathOr("", ["permissionSetId"], formValues)}
                onSelect={(val) => handleChange(val, "permissionSetId")}
              />
              {pathOr(null, ["PermissionSet"], errors) ? (
                <span className="text-red-500">{pathOr("", ["PermissionSet", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Locations<span className="text-red-500">*</span>
              </span>
              <Select
                mode="tags"
                className="w-full"
                status={pathOr(null, ["Locations"], errors) ? "error" : ""}
                value={pathOr([], ['locations'], formValues).map(
                  (location) => pathOr('', ['locationId'], location))}
                onChange={(val) => handleChange(val, "locations")}
                options={locations.map((location: any) => ({
                  value: pathOr('', ['id'], location),
                  label: pathOr('', ['name'], location).toUpperCase() })
                )}
              />
              {pathOr(null, ["Locations"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Locations", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>
        </div>
       </div>
     </div>
  );
};

export default EmployeeForm;
