import {getCookie} from "helpers/utilities";
import axios from "axios";
import {pathOr} from "ramda";
import {message} from "antd";

export interface ApiResponse {
  // Define the structure of the API response data
  // Adjust the types according to your API response structure
}

export const getAuthorizationHeader = (): Record<string, string> => {
  const bearerToken = getCookie('accessToken');

  if (!bearerToken) {
    throw new Error('Access token not found');
  }

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearerToken}`,
  };
};

export const handleAxiosError = (error: any, thunkAPI: any) => {
  if (axios.isAxiosError(error)) {
    const mes = pathOr('', ['response', 'data', 'error'], error) || pathOr('', ['message'], error);
    const errorArray = pathOr(null, ['response', 'data', 'message'], error);
    
    if (errorArray) {
      for (const err of errorArray) {
        message.error(err);
      }
      throw thunkAPI.rejectWithValue(
        pathOr('',[0], errorArray)
      );
    } else {
      message.error(mes)
      throw thunkAPI.rejectWithValue(
        pathOr('', ['response', 'data', 'error'], error)
      );
    }
  } else {
    message.error('failed to make request, try again later');
    throw thunkAPI.rejectWithValue('failed to make request, try again later');
  }
};

export const handleApiResponse = async (response: Response): Promise<ApiResponse> => {
  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return await response.json();
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    Authorization: `Bearer ${getCookie('accessToken')}`,
    'Cache-Control': 'no-cache',
  },
});
