// Admin Imports
import Menu from "views/admin/menus";
import Expenses from "views/admin/expenses";
import Purchases from "views/admin/purchases";
import Suppliers from "views/admin/suppliers";
import Dashboard from "views/admin/dashboard";
import SalesReport from "views/admin/reports/sales-report";
import GeneralSettings from "views/admin/settings";
import GroupPermissions from "views/admin/permission-sets";
import TableManagement from "views/admin/floors";
import Employees from "views/admin/employees";
import {
  MdOutlineSpaceDashboard,
  MdOutlinePeopleAlt,
  MdOutlineAccountBalanceWallet,
  MdOutlineInventory2,
  MdShoppingBag,
  MdOutlineSettings,
  MdOutlineEqualizer,
  MdMenuBook,
  MdOutlineTableRestaurant,
  MdOutlineDiversity1,
  MdOutlineLockPerson,
  MdOutlineAttachMoney,
} from "react-icons/md";
import { TbReceipt } from "react-icons/tb";
import { RiUserSettingsLine } from "react-icons/ri";
import Inventory from "views/admin/inventory";
import OrdersReport from "views/admin/reports/orders-report";
import InventoryReport from "views/admin/reports/inventory-report";
import ModifierItemsReport from "views/admin/reports/modifier-items-report";
import MenuItemsReport from "views/admin/reports/menu-items-report";
import PurchasesReport from "views/admin/reports/purchases-report";
import ExpensesReport from "views/admin/reports/expenses-report";
import {
  DASHBOARD_ROUTE,
  EMPLOYEES_ROUTE,
  EXPENSES_REPORT_ROUTE,
  EXPENSES_ROUTE,
  FLOORS_ROUTE,
  INVENTORY_REPORT_ROUTE,
  INVENTORY_ROUTE,
  MENU_ITEMS_REPORT_ROUTE,
  MENU_MODIFIER_GROUPS_ROUTE,
  MENUS_ROUTE,
  MODIFIER_ITEMS_REPORT_ROUTE,
  ORDERS_REPORT_ROUTE,
  ORDERS_ROUTE,
  PURCHASES_REPORT_ROUTE,
  PURCHASES_ROUTE,
  REPORTS_ROUTE,
  PERMISSION_SETS_ROUTE,
  SALES_REPORT_ROUTE,
  SETTINGS_ROUTE,
  SUPPLIERS_ROUTE,
  INVENTORY_OVERTIME_REPORT_ROUTE,
  MENU_ITEMS_OVERTIME_REPORT_ROUTE,
  MODIFIER_ITEMS_OVERTIME_REPORT_ROUTE,
  MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT_ROUTE,
  CUSTOMERS_ROUTE
} from "./constants";
import Orders from "views/admin/orders";
import InventoryOverTimeReport from "views/admin/reports/inventory-report/inventory-overtime-report";
import MenuItemsOverTimeReport from "views/admin/reports/menu-items-report/menu-items-overtime-report";
import MenuItemsSoldByEmployeesReport from "views/admin/reports/employees-report/menu-items-sold-by-employees";
import MenuModifierItemsOverTimeReport from "views/admin/reports/modifier-items-report/modifier-items-overtime-report";
import { 
  CAN_VIEW_CUSTOMERS,
  CAN_VIEW_DASHBOARD,
  CAN_VIEW_EMPLOYEES,
  CAN_VIEW_EXPENSES,
  CAN_VIEW_EXPENSES_REPORT,
  CAN_VIEW_FLOORS_AND_TABLES,
  CAN_VIEW_INVENTORY,
  CAN_VIEW_INVENTORY_OVERTIME_REPORT,
  CAN_VIEW_INVENTORY_REPORT,
  CAN_VIEW_MENU_ITEMS_REPORT,
  CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT,
  CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT,
  CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT,
  CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT,
  CAN_VIEW_MENUS,
  CAN_VIEW_ORDERS,
  CAN_VIEW_ORDERS_REPORT,
  CAN_VIEW_PERMISSION_SETS,
  CAN_VIEW_PURCHASES,
  CAN_VIEW_PURCHASES_REPORT,
  CAN_VIEW_SALES_REPORT,
  CAN_VIEW_SUPPLIERS
 } from "permissions";
import Customers from "views/admin/customers";

export const sidebarNavigation = {
  routes: [
    {
      label: "Dashboard",
      key: DASHBOARD_ROUTE,
      component: <Dashboard permissionsNeeded={[CAN_VIEW_DASHBOARD]} />,
      icon: <MdOutlineSpaceDashboard className="mr-4 h-5 w-5" />,
      permissionsNeeded: [CAN_VIEW_DASHBOARD]
    },
    {
      label: "Menus",
      key: `${MENUS_ROUTE}-menu`,
      icon: <MdMenuBook className="mr-4 h-5 w-5" />,
      permissionsNeeded: [CAN_VIEW_MENUS],
      children: [
        {
          label:'Menus',
          key: MENUS_ROUTE,
          component: <Menu permissionsNeeded={[CAN_VIEW_MENUS]}  />,
          permissionsNeeded: [CAN_VIEW_MENUS]
        },
        {
          label:'Modifier Groups',
          key: MENU_MODIFIER_GROUPS_ROUTE,
          component: <Menu permissionsNeeded={[CAN_VIEW_MENUS]} />,
          permissionsNeeded: [CAN_VIEW_MENUS]
        }
      ]
    },
    {
      label: "Orders",
      key: ORDERS_ROUTE,
      component: <Orders permissionsNeeded={[CAN_VIEW_ORDERS]} />,
      icon: <MdShoppingBag className="mr-4 h-5 w-5" />,
      permissionsNeeded: [CAN_VIEW_ORDERS]
    },
    {
      label: "Floor Planning",
      key: FLOORS_ROUTE,
      component: <TableManagement permissionsNeeded={[CAN_VIEW_FLOORS_AND_TABLES]} />,
      icon: <MdOutlineTableRestaurant className="mr-4 h-5 w-5" />,
      permissionsNeeded: [CAN_VIEW_FLOORS_AND_TABLES]
    },
    {
      label: "Inventory Management",
      key: `${INVENTORY_ROUTE}-inventory`,
      component: <Inventory permissionsNeeded={[CAN_VIEW_INVENTORY]} />,
      icon: <MdOutlineInventory2 className="mr-4 h-5 w-5" />,
      children: [
        {
          label:'Inventory',
          key: INVENTORY_ROUTE,
          component: <Inventory permissionsNeeded={[CAN_VIEW_INVENTORY]} />,
          permissionsNeeded: [CAN_VIEW_INVENTORY]
        },
        {
          label: "Suppliers",
          key: SUPPLIERS_ROUTE,
          component: <Suppliers permissionsNeeded={[CAN_VIEW_INVENTORY]} />,
          permissionsNeeded: [CAN_VIEW_SUPPLIERS]
        },
      ],
    },
    {
      label: "Finance Management",
      key: `finance-management`,
      icon: <MdOutlineAttachMoney className="mr-4 h-5 w-5" />,
      children: [
        {
          label: "Expenses",
          key: EXPENSES_ROUTE,
          component: <Expenses permissionsNeeded={[CAN_VIEW_EXPENSES]} />,
          icon: <MdOutlineAccountBalanceWallet className="mr-4 h-5 w-5" />,
          permissionsNeeded: [CAN_VIEW_EXPENSES]
        },
        {
          label: "Purchases",
          key: PURCHASES_ROUTE,
          component: <Purchases permissionsNeeded={[CAN_VIEW_PURCHASES]} />,
          icon: <TbReceipt className="mr-4 h-5 w-5" />,
          permissionsNeeded: [CAN_VIEW_PURCHASES]
        },
      ],
    },
    {
      label: "People & Permissions",
      key: `people-and-permissions`,
      icon: <RiUserSettingsLine className="mr-4 h-5 w-5" />,
      children: [
        {
          label: "Employees",
          key: EMPLOYEES_ROUTE,
          component: <Employees permissionsNeeded={[CAN_VIEW_EMPLOYEES]} />,
          icon: <MdOutlinePeopleAlt className="mr-4 h-5 w-5" />,
          permissionsNeeded: [CAN_VIEW_EMPLOYEES]
        },
        {
          label: "Customers",
          key: CUSTOMERS_ROUTE,
          component: <Customers permissionsNeeded={[CAN_VIEW_CUSTOMERS]} />,
          icon: <MdOutlineDiversity1 className="mr-4 h-5 w-5" />,
          permissionsNeeded: [CAN_VIEW_EMPLOYEES]
        },
        {
          label: "Permission Sets",
          key: PERMISSION_SETS_ROUTE,
          component: <GroupPermissions permissionsNeeded={[CAN_VIEW_PERMISSION_SETS]} />,
          icon: <MdOutlineLockPerson className="mr-4 h-5 w-5" />,
          sidebarDisplay: true,
          permissionsNeeded: [CAN_VIEW_PERMISSION_SETS]
        },
      ],
    },
    {
      label: "Settings",
      key: SETTINGS_ROUTE,
      component: <GeneralSettings />,
      icon: <MdOutlineSettings className="mr-4 h-5 w-5" />,
    },
    {
      label: "Reports",
      key: REPORTS_ROUTE,
      icon: <MdOutlineEqualizer className="mr-4 h-5 w-5" />,
      children: [
        {
          label: "Expenses Report",
          key: `${EXPENSES_REPORT_ROUTE}`,
          component: <ExpensesReport permissionsNeeded={[CAN_VIEW_EXPENSES_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_EXPENSES_REPORT]
        },
        {
          label: "Purchases Report",
          key: `${PURCHASES_REPORT_ROUTE}`,
          component: <PurchasesReport permissionsNeeded={[CAN_VIEW_PURCHASES_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_PURCHASES_REPORT]
        },
        {
          label: "Menu Items Report",
          key: `${MENU_ITEMS_REPORT_ROUTE}`,
          component: <MenuItemsReport permissionsNeeded={[CAN_VIEW_MENU_ITEMS_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_MENU_ITEMS_REPORT]
        },
        {
          label: "Menu Items Overtime Report",
          key: `${MENU_ITEMS_OVERTIME_REPORT_ROUTE}`,
          component: <MenuItemsOverTimeReport permissionsNeeded={[CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT]
        },
        {
          label: "Menu Items Sold By Employees",
          key: `${MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT_ROUTE}`,
          component: <MenuItemsSoldByEmployeesReport 
          permissionsNeeded={[CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT]
        },
        {
          label: "Modifier Items Report",
          key: `${MODIFIER_ITEMS_REPORT_ROUTE}`,
          component: <ModifierItemsReport permissionsNeeded={[CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT]
        },
        {
          label: "Modifier Items OverTime Report",
          key: `${MODIFIER_ITEMS_OVERTIME_REPORT_ROUTE}`,
          component: <MenuModifierItemsOverTimeReport 
          permissionsNeeded={[CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT]
        },
        {
          label: "Sales Report",
          key: `${SALES_REPORT_ROUTE}`,
          component: <SalesReport permissionsNeeded={[CAN_VIEW_SALES_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_SALES_REPORT]
        },
        {
          label: "Inventory Report",
          key: `${INVENTORY_REPORT_ROUTE}`,
          component: <InventoryReport permissionsNeeded={[CAN_VIEW_INVENTORY_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_INVENTORY_REPORT]
        },
        {
          label: "Inventory Overtime Report",
          key: `${INVENTORY_OVERTIME_REPORT_ROUTE}`,
          component: <InventoryOverTimeReport permissionsNeeded={[CAN_VIEW_INVENTORY_OVERTIME_REPORT]} />,
          permissionsNeeded: [CAN_VIEW_INVENTORY_OVERTIME_REPORT]
        },
        {
          label: "Orders Report",
          key: `${ORDERS_REPORT_ROUTE}`,
          component: <OrdersReport permissionsNeeded={[CAN_VIEW_ORDERS_REPORT]}  />,
          permissionsNeeded: [CAN_VIEW_ORDERS_REPORT]
        },
      ]
    },
  ],
  rootSubMenuKeys: ['/menus', 'inventory', 'reports']
}
