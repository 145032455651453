import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const signInWithEmailAndPasswordAPI = createAsyncThunk(
   'auth/sign-in-user',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/login/email-password`, {
         method: 'POST',
         data: {
           ...payload.user,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const signUpAPI = createAsyncThunk(
   'auth/sign-up-user',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/signup`, {
         method: 'POST',
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const setupAccountAPI = createAsyncThunk(
   'auth/set-up-account',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/setup-account`, {
         method: 'PATCH',
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateUserAccountAPI = createAsyncThunk(
   'auth/update-user-account',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/users`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const signOutAPI = createAsyncThunk(
   'auth/sign-out-user',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/sign-out`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

