import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  firstname: Joi.string().trim().required().label('Firstname'),
  lastname: Joi.string().trim().required().label('Lastname'),
  phoneNumber: Joi.string().trim().required().label('PhoneNumber'),
  email: Joi.string().trim().required().label('Email'),
  password: Joi.string().trim().optional().label('Password'),
  passcode: Joi.string().trim().optional().label('Passcode'),
  employeeRole: Joi.string().trim().required().label('Employee Role'),
  permissionSet: Joi.string().trim().required().label('Permission Set'),
  salary: Joi.number().positive().label('Salary'),
  locations: Joi.array().items(
    Joi.string()
 ).required().label('Locations'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
